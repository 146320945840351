<mat-sidenav-container fullscreen autosize>
  <!-- Sidenav -->
  <mat-sidenav #sidenav mode="over">

    <div class="dev-configurations-container" *ngrxLet="devVersionData$; let devVersionData">
      <!-- Title -->
      <h3 class="dev-configurations-title">Dev configuration</h3>

      <!-- BE version select -->
      <app-select-search *ngIf="devVersionData && isDeveloper"
                         [objects]="devVersionData.beDevVersions"
                         [selectedObject]="selectedBeDevVersion$ | ngrxPush"
                         [enableClear]="false"
                         (selectedObjectChange)="setSelectedBeDevVersion($event)"
                         [disabled]="(selectedRegion$ | ngrxPush) === DevVersionService.CN_REGION"
                         placeHolder="BE version"></app-select-search>

      <!-- FE version select -->
      <app-select-search *ngIf="devVersionData && isDeveloper"
                         [objects]="devVersionData.feDevVersions"
                         [selectedObject]="selectedFeDevVersion$ | ngrxPush"
                         [enableClear]="false"
                         (selectedObjectChange)="setSelectedFeDevVersion($event)"
                         placeHolder="FE version"></app-select-search>

      <!-- BE region select -->
      <app-o7-select *ngIf="devVersionData"
                     [options]="devVersionData.regions"
                     [selectedValue]="selectedRegion$ | ngrxPush"
                     (selectionChange)="setSelectedRegion($event)"
                     [showClearButton]="false"
                     class="region-select"
                     label="BE region"></app-o7-select>

      <!-- Use dev environment checkbox -->
      <mat-checkbox [checked]="useDevEnvironment$ | ngrxPush" (change)="setSelectedEnvironment($event.checked)" *ngIf="isDeveloper">
        Use dev environment
      </mat-checkbox>
    </div>

    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- List of dashboards -->
    <mat-nav-list role="navigation" class="menu">
      <ng-container *ngFor="let dashboard of dashboards">
        <app-dashboard-menu-item [dashboard]="dashboard"></app-dashboard-menu-item>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Toolbar -->
  <mat-sidenav-content role="main">
    <mat-toolbar color="primary" *ngIf="!isExternalMediationPartner()">
      <!-- Sidenav toggle -->
      <span>
        <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      </span>
      <!-- Title -->
      <span class="toolbar-title visual7-title" routerLink="/">
        Visual 7
        <ng-container *ngIf="useDevEnvironment$ | ngrxPush"> - dev</ng-container>
      </span>

      <!-- Flex to fill in the void -->
      <span fxFlex></span>
      <!-- Account menu -->
      <button [matMenuTriggerFor]="accountMenu" mat-button>
        <div fxLayout="row" fxLayoutAlign=" center">
          <mat-icon>person</mat-icon>
          <span class="username-text">{{usernameOrEmail}}</span>
        </div>
      </button>
      <mat-menu #accountMenu="matMenu" hasBackdrop="true">
        <a mat-menu-item href="https://accounts-be.outfit7.net/auth/realms/backend/account" target="_blank">Manage
          account</a>
        <a *ngIf="isRealmManager" mat-menu-item href="https://accounts-be.outfit7.net/auth/admin/backend/console"
           target="_blank">Admin Console</a>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar>

    <mat-toolbar color="primary" *ngIf="isExternalMediationPartner()" class="external-partner">
      <img src="./assets/images/outfit7_logo_white.png" alt="logo" height="40%"/>

      <!-- Flex to fill in the void -->
      <span fxFlex></span>
      <!-- Account menu -->
      <button [matMenuTriggerFor]="accountMenu" mat-button>
        <div fxLayout="row" fxLayoutAlign=" center">
          <mat-icon>person</mat-icon>
          <span class="username-text">{{usernameOrEmail}}</span>
        </div>
      </button>
      <mat-menu #accountMenu="matMenu" hasBackdrop="true">
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar>

    <mat-progress-bar class="menu-progress-bar" mode="indeterminate" *ngIf="loadingBarService.progress$|async"></mat-progress-bar>

    <div class="main-container">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
