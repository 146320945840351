import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import * as _ from 'lodash';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationService} from '../authentication/authentication.service';
import {EMPTY, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {HttpClient} from '@angular/common/http';
import {DevVersionData} from './dev-version-data';
import {WINDOW} from '../providers/window-provider';
import {environment} from '@environment';
import {Builder} from 'builder-pattern';
import {State} from '@ngrx/store';
import QueryParamUtils from '../utils/query-param-utils';
import {UserRole} from '../authentication/user-role';

@Injectable({
  providedIn: 'root'
})
export class DevVersionService {

  public static readonly BE_DEV_VERSION_QUERY_PARAM: string = 'beDevVersion';
  public static readonly REGION_QUERY_PARAM: string = 'region';
  public static readonly ENVIRONMENT_QUERY_PARAM: string = 'env';
  public static readonly DEV_ENVIRONMENT: string = 'dev';
  public static readonly PROD_ENVIRONMENT: string = 'prod';
  public static readonly PROD_VERSION: string = 'prod';
  public static readonly CLOUDFLARE_HOSTNAME_SUFFIX: string = '.o7apps-visual7.pages.dev';
  public static readonly LOCALHOST_HOSTNAME: string = 'localhost';
  public static readonly DEV_GAE_VERSION_ENV_VARIABLE: string = 'devGaeVersion';

  public static readonly GLOBAL_REGION: string = 'global';
  public static readonly CN_REGION: string = 'cn';

  private readonly BASE_URL: string = '/rest/visual7/dev-versions';

  constructor(private cookieService: CookieService,
              @Inject(WINDOW) private window: Window,
              private authenticationService: AuthenticationService,
              private http: HttpClient,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              // Store state enables to return values directly from the store without using observables
              private state: State<any>
  ) {}

  getDevVersionData(): Observable<DevVersionData> {
    const url: string = 'https://' + (this.state.getValue().applicationState.useDevEnvironment ? 'o7apps-dev.outfit7.net' : 'apps.outfit7.com') + this.BASE_URL;

    if (!this.authenticationService.hasRole(UserRole.DEVELOPER)) {
      return of(Builder(DevVersionData)
        .beDevVersions([DevVersionService.PROD_VERSION])
        .regions([{value: DevVersionService.GLOBAL_REGION, viewValue: 'Global'}, {value: DevVersionService.CN_REGION, viewValue: 'China'}])
        .feDevVersions([DevVersionService.PROD_VERSION,  DevVersionService.LOCALHOST_HOSTNAME])
        .build());
    }

    return this.http.get<DevVersionData>(url)
      .pipe(
        map(response => {
          // Add prod and localhost versions
          return Builder(DevVersionData, plainToClass(DevVersionData, response))
            .beDevVersions([DevVersionService.PROD_VERSION,
              ...(_.filter(response.beDevVersions, v => v != DevVersionService.PROD_VERSION))])
            .regions([{value: DevVersionService.GLOBAL_REGION, viewValue: 'Global'}, {value: DevVersionService.CN_REGION, viewValue: 'China'}])
            .feDevVersions([DevVersionService.PROD_VERSION, DevVersionService.LOCALHOST_HOSTNAME,
              ...(_.map(response.feDevVersions, feVersion => feVersion.replace(new RegExp('_', 'g'), '-')))])
            .build();
        })
      );
  }

  resolvePreSetBeDevVersion(): string {
    const hostname: string = this.window.location.hostname;
    let devVersion: string = DevVersionService.PROD_VERSION;

    if (window.location.search.includes(DevVersionService.BE_DEV_VERSION_QUERY_PARAM)) {
      let regex: RegExp = new RegExp('[\\?&]' + DevVersionService.BE_DEV_VERSION_QUERY_PARAM + '=([^&#]*)');
      let result: RegExpMatchArray = regex.exec(window.location.search);
      devVersion = result == null ? '' : decodeURIComponent(result[1].replace(/\+/g, ' '));
    } else if (hostname == 'localhost' && _.get(environment, DevVersionService.DEV_GAE_VERSION_ENV_VARIABLE)) {
      // Resolve version from env variables (used when developing locally)
      devVersion =  _.get(environment, DevVersionService.DEV_GAE_VERSION_ENV_VARIABLE);
    }

    return devVersion;
  }

  resolvePreSetRegion(): string {
    let region: string = DevVersionService.GLOBAL_REGION;
    if (window.location.search.includes(`region=${DevVersionService.CN_REGION}`)) {
      region = DevVersionService.CN_REGION;
    }
    return region;
  }

  resolvePreSetFeDevVersion(): string {
    // Resolve FE version from hostname
    let hostname: string = this.window.location.hostname;
    if (hostname.startsWith(DevVersionService.LOCALHOST_HOSTNAME)) {
      return DevVersionService.LOCALHOST_HOSTNAME;
    } else if (hostname.endsWith(DevVersionService.CLOUDFLARE_HOSTNAME_SUFFIX)) {
      return hostname.replace(DevVersionService.CLOUDFLARE_HOSTNAME_SUFFIX, '');
    }

    return DevVersionService.PROD_VERSION;
  }

  shouldUseDevEnvironment(): boolean {
    if (window.location.search.includes(DevVersionService.ENVIRONMENT_QUERY_PARAM)) {
      let regex: RegExp = new RegExp('[\\?&]' + DevVersionService.ENVIRONMENT_QUERY_PARAM + '=([^&#]*)');
      let result: RegExpMatchArray = regex.exec(window.location.search);
      return decodeURIComponent(result[1].replace(/\+/g, ' ')) == DevVersionService.DEV_ENVIRONMENT;
    } else if (!environment.angularProductionMode) {
      return true;
    }
    return false;
  }

  navigateToBeVersion(beDevVersion: string): void {
    this.reloadWithUpdatedQueryParam(DevVersionService.BE_DEV_VERSION_QUERY_PARAM, beDevVersion);
  }

  navigateToRegion(region: string): void {
    this.reloadWithUpdatedQueryParam(DevVersionService.REGION_QUERY_PARAM, region);
  }

  navigateToEnvironment(useDevEnvironment: boolean): void {
    this.reloadWithUpdatedQueryParam(DevVersionService.ENVIRONMENT_QUERY_PARAM,
      useDevEnvironment ? DevVersionService.DEV_ENVIRONMENT : DevVersionService.PROD_ENVIRONMENT);
  }

  /**
   * Correct way of navigation is the one used in query param service but since it interferes with routing on page init we use href here
   * to bypass that.
   */
  private reloadWithUpdatedQueryParam(queryParam: string, newValue: string): void {
    let currentQueryParams: string = this.window.location.search;
    let currentWindowLocationHref: string = this.window.location.href;

    // change BE version to prod if China region is selected
    if (queryParam === DevVersionService.REGION_QUERY_PARAM && newValue === DevVersionService.CN_REGION) {
    let beDevProdVersionParam: string = DevVersionService.BE_DEV_VERSION_QUERY_PARAM + '=' + DevVersionService.PROD_VERSION;
      let beDevVersionParamStartIndex: number = currentQueryParams.indexOf(DevVersionService.BE_DEV_VERSION_QUERY_PARAM);
      if (beDevVersionParamStartIndex != -1) {
        let beDevVersionParamEndIndex: number = currentQueryParams.indexOf('&', beDevVersionParamStartIndex);
        let beDevVersionParam: string = currentQueryParams.substring(beDevVersionParamStartIndex,
          beDevVersionParamEndIndex != -1 ? beDevVersionParamEndIndex : currentQueryParams.length);
        currentWindowLocationHref = this.window.location.href.replace(beDevVersionParam, beDevProdVersionParam);
      }
    }

    let updatedParam: string = queryParam + '=' + newValue;
    // Update query param value and href
    let paramStartIndex: number = currentQueryParams.indexOf(queryParam);
    if (paramStartIndex != -1) {
      // Replace query param
      let paramEndIndex: number = currentQueryParams.indexOf('&', paramStartIndex);
      let currentParam: string = currentQueryParams.substring(paramStartIndex,
        paramEndIndex != -1 ? paramEndIndex : currentQueryParams.length);
      this.window.location.href = currentWindowLocationHref.replace(currentParam, updatedParam);
    } else {
      // Add new query param
      const url: URL = new URL(this.window.location.href);
      console.log(url.searchParams);
      url.searchParams.append(queryParam, newValue);
      this.window.location.href = url.toString();
    }
  }

  navigateToFeVersion(feDevVersion: string): void {
    if (feDevVersion == DevVersionService.PROD_VERSION) {
      window.location.href = 'https://visual7.outfit7.net' + this.router.url;
    } else if (feDevVersion == DevVersionService.LOCALHOST_HOSTNAME) {
      window.location.href = 'http://' + DevVersionService.LOCALHOST_HOSTNAME + ':4200' + this.router.url;
    } else {
      window.location.href = 'https://' + feDevVersion + DevVersionService.CLOUDFLARE_HOSTNAME_SUFFIX + this.router.url;
    }
  }

  resolveEnvironment(routeUrl: string, routerState: RouterStateSnapshot): boolean | UrlTree {
    const envQueryParam: string = routerState.root.queryParamMap.get(DevVersionService.ENVIRONMENT_QUERY_PARAM);
    const selectedEnv: string = this.state.getValue().applicationState.useDevEnvironment ?
      DevVersionService.DEV_ENVIRONMENT : DevVersionService.PROD_ENVIRONMENT;
    let result: boolean | UrlTree = true;
    if (envQueryParam != selectedEnv) {
      result = this.appendParamToQueryParams(routeUrl, routerState, DevVersionService.ENVIRONMENT_QUERY_PARAM, selectedEnv);
    }

    return result;
  }

  resolveDevVersion(routeUrl: string, routerState: RouterStateSnapshot): boolean | UrlTree {
    const devVersionQueryParam: string = routerState.root.queryParamMap.get(DevVersionService.BE_DEV_VERSION_QUERY_PARAM);
    const selectedBeDevVersion: string = this.state.getValue().applicationState.beDevVersion;
    let result: boolean | UrlTree = true;
    if (selectedBeDevVersion != null && devVersionQueryParam != selectedBeDevVersion) {
      if (devVersionQueryParam == null && selectedBeDevVersion == DevVersionService.PROD_VERSION) {
        result = true;
      } else {
        result = this.appendParamToQueryParams(routeUrl, routerState, DevVersionService.BE_DEV_VERSION_QUERY_PARAM, selectedBeDevVersion);
      }
    }

    return result;
  }

  resolveRegion(routeUrl: string, routerState: RouterStateSnapshot): boolean | UrlTree {
    const regionQueryParam: string = routerState.root.queryParamMap.get(DevVersionService.REGION_QUERY_PARAM);
    const selectedRegion: string = this.state.getValue().applicationState.region;

    let result: boolean | UrlTree = true;
    if (selectedRegion != null && regionQueryParam != selectedRegion) {
      if (regionQueryParam == null && selectedRegion == DevVersionService.GLOBAL_REGION) {
        result = true;
      } else {
        result = this.appendParamToQueryParams(routeUrl, routerState, DevVersionService.REGION_QUERY_PARAM, selectedRegion);
      }
    }

    return result;
  }

  private appendParamToQueryParams(routeUrl: string, routerState: RouterStateSnapshot, paramName: string,  paramValue: string): UrlTree {
    let routeWithoutQueryParams: string = decodeURIComponent(QueryParamUtils.getUrlWithoutParamsPart(routeUrl));
    // Until https://github.com/angular/angular/issues/27148 is closed, NavigationExtras#State and some others are not supported
    const navigationExtras: NavigationExtras = Builder(this.router.getCurrentNavigation().extras)
      .queryParams(QueryParamUtils.mergeRouterQueryParams(routerState.root.queryParams,
        {
          [paramName]: paramValue
        }))
      .build();
    return this.router.createUrlTree([routeWithoutQueryParams], navigationExtras);
  }
}
