import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {map, switchMap} from 'rxjs/operators';
import {DevVersionService} from '../../core/dev-versions/dev-version.service';
import {setDevVersionData} from './dev-version.actions';
import {KEYCLOAK_INIT} from '../common/module-initialization.actions';
import {DevVersionData} from '../../core/dev-versions/dev-version-data';

@Injectable({
  providedIn: 'root'
})
export class DevVersionEffects {

  constructor(private actions$: Actions,
              private devVersionService: DevVersionService) {
  }

  getDevVersionData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(KEYCLOAK_INIT),
    switchMap(() => this.devVersionService.getDevVersionData()),
    map((devVersionData: DevVersionData) => {
      return setDevVersionData({devVersionData: devVersionData});
    })
  ));

}
